(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name cancellations.controller:CancellationsCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.cancellations')
    .controller('CancellationsCtrl', CancellationsCtrl);

    function CancellationsCtrl($q, $filter, $state, Matches, townHalls) {
    var vm = this;
    vm.ctrlName = 'CancellationsCtrl';

      vm.showList = false;
      vm.matches = [];
      vm.today = moment().toDate();
      vm.fifteenDaysAgo = moment(vm.today).subtract(15, 'days').toDate();
      vm.endDate = vm.today;
      vm.initDate = vm.fifteenDaysAgo;
      vm.townhalls = _.cloneDeep(townHalls);
      vm.queryOrder = 'date';
      vm.sort = 'date';

      vm.openMenu = function ($mdMenu, event) {
        $mdMenu.open(event);
      };
      vm.currentPage = 1;

      vm.matchesPerPage = 25;
      vm.paginationLabel = {
        page: $filter('translate')('PAGE'),
        rowsPerPage: '',
        of: $filter('translate')('OF')
      };

      if (vm.townhalls.length === 1) {
        vm.selectedTownHall = vm.townhalls[0];
        applyFilters();
      }

      vm.applyFilters = applyFilters;
      vm.reorder = reorder;
      vm.getNewPage = getNewPage;
      vm.cancelMatch = cancelMatch;

      function cancelMatch(match) {
        $state.go('home.cancellations.new', {matchId: match.id});
      }

      function applyFilters() {
        vm.isFiltered = true;
        query(vm.selectedTownHall.id, vm.initDate, vm.endDate, vm.currentPage, vm.sort);
      }

      function reorder(order) {
        vm.sort = order;
        query(vm.selectedTownHall.id, vm.initDate, vm.endDate, vm.currentPage, vm.sort);
      }

      function hasValue(field) {
        return !_.isNull(field) && !_.isUndefined(field)
      }

      function canCancel(match) {
        if (!hasValue(match.localTeamId)) return false;
        if (!hasValue(match.visitorTeamId)) return false;
        if (hasValue(match.lastCancellation) && !match.lastCancellation.resolved) return false;
        return moment(vm.fifteenDaysAgo).diff(moment(moment(match.date)), 'days') <= 15;
      }

      function parseMatch(data, match) {
        var facility = findItemById(data.facilities, match.facilityId);
        var localTeam = findItemById(data.teams, match.localTeamId);
        var visitorTeam = findItemById(data.teams, match.visitorTeamId);
        return {
          id: match.id,
          canCancel: canCancel(match),
          localTeam: angular.isDefined(localTeam) ? localTeam : {name: $filter('translate')('CALENDAR.REST')},
          visitorTeam: angular.isDefined(visitorTeam) ? visitorTeam : {name: $filter('translate')('CALENDAR.REST')},
          date: moment(match.date).format('L'),
          time: _.isNull(match.time) ? undefined : match.time,
          facility: angular.isDefined(facility) ? facility : undefined,
          championship: findItemById(data.championships, match.championshipId).name
        };
      }

      function query(townHallId, initDate, endDate, page, sort) {
        var deferredMatches = $q.defer();
        vm.promiseMatches = deferredMatches.promise;
        var parameters = {
          townHall: townHallId,
          initDate: moment(initDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
          page: page,
          size: vm.matchesPerPage
        };
        //Optional parameters
        if (angular.isDefined(sort)) {
          parameters.sort = sort;
        }
        Matches.get(parameters, function (data) {
          _.remove(vm.matches);
          angular.forEach(data.matches, function (match) {
            vm.matches.push(parseMatch(data, match));
          });
          if (vm.matches.length !== 0) {
            vm.showList = true;
          }
          vm.maxMatches = data.page.count;
          deferredMatches.resolve();
        }, function () {
          deferredMatches.resolve();
        });
      }

      function getNewPage(page) {
        query(vm.selectedTownHall.id, vm.initDate, vm.endDate, page, vm.sort);
      }

      function findItemById(collection, itemId) {
        return _.find(collection, function (i) {
          return i.id === itemId;
        });
      }
    }
  }()
);
